<template>
  <section
    v-show="isVisible"
    class="mb-xl mt-xl">
    <div
      class="game"
      :class="{ 'is-open': internalVisible }">
      <div
        class="game-header"
        @click="setVisibilty(!internalVisible)">
        <button
          type="button"
          class="game__handler"
          :title="
            internalVisible
              ? 'Скрыть космическую новеллу'
              : 'Показать космическую новеллу'
          "
          @click.prevent.stop="setVisibilty(!internalVisible)">
          <span></span>
        </button>
        <div class="game-content">
          <h3 class="text-size-h3 text-bold mb-xs">
            &laquo;Журнал капитана Тундры&raquo;
          </h3>
          <div class="color-meta text-size-s">
            Если вы&nbsp;уже всё решили в&nbsp;этом туре, можете прочитать
            космическую новеллу, пока ждёте новых заданий олимпиады!
          </div>
        </div>
      </div>
      <div
        v-show="internalVisible"
        class="game__frame">
        <iframe
          ref="frame"
          :src="IFRAME_SRC"
          allowfullscreen
          frameborder="0"
          @load="onIframeLoad"></iframe>
      </div>
    </div>
  </section>
</template>

<script>
import { getToken } from "@/services/api";

export default {
  name: "NovelSection",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      internalVisible: false,
    };
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.setVisibilty(false);
        }
      },
    },
  },
  created() {
    this.IFRAME_SRC = `${process.env.VUE_APP_TALENT_BASE_URL}/novel/nto_connect`;
  },
  methods: {
    postMessage(data) {
      if (this.$refs.frame) {
        this.$refs.frame.contentWindow.postMessage(
          data,
          process.env.VUE_APP_TALENT_BASE_URL
        );
      }
    },
    onIframeLoad() {
      setTimeout(() => {
        this.postMessage({
          token: getToken(),
        });
      }, 4000);
    },
    setVisibilty(val) {
      this.internalVisible = val;
      this.postMessage({
        mute: !val,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.game {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: @shadow-default;

  &-header {
    display: flex;
    flex-wrap: nowrap;
  }

  &.is-hidden &-header {
    margin-bottom: 0px;
  }

  &__handler {
    width: 32px;
    height: 32px;
    display: block;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    outline: none;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;

    span {
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid;
      transform: rotateZ(45deg);
      border-top: 0;
      border-left: 0;
      top: 50%;
      left: 50%;
      margin-top: -4px;
      margin-left: -6px;
      transition: color 0.3s, transform 0.3s;
      transform-origin: center center;
    }
  }

  &.is-open &__handler span {
    transform: rotateZ(-135deg);
    margin-top: -6px;
  }

  &__frame {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    min-height: 700px;
    margin-top: 20px;

    iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    &__frame {
      min-height: 100vh;
      min-height: calc(~"100vh - 56px - 54px"); // верхняя и нижняя менюшки
    }

    &-header {
      padding: 20px;
    }
  }
}
</style>
