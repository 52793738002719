<template>
  <div class="invite-friend">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <div class="mb-m">
        <h2 class="text-bold text-size-h2 mb-s">Зови друзей!</h2>
        <p class="mb-m">
          Чтобы пригласить друзей, скопируй и&nbsp;отправь им ссылку
          в&nbsp;мессенджере, соцсети или по&nbsp;e-mail.
        </p>
        <p class="text-bold text-size-h4 text-center mb-m">
          Готовься к&nbsp;командному этапу уже сейчас&nbsp;&mdash; приглашай
          тех, в&nbsp;чьи силы ты&nbsp;веришь!
        </p>
      </div>
      <div>
        <BaseImage
          class="invite-friend__image mb-l"
          :aspect-ratio="201 / 280"
          src="@/assets/images/invite-friend.svg" />

        <p class="text-bold text-size-h4 text-center mb-m">
          НТО&nbsp;&mdash; командная олимпиада, пройти в&nbsp;финал одному
          и&nbsp;победить не&nbsp;получится. Собирай команду уже сейчас!
        </p>

        <div class="invite-friend__form">
          <BaseInput
            id="invite-link-mentor"
            class="invite-friend__input"
            :value="link"
            readonly />

          <BaseButton
            id="modal-copy-link"
            class="invite-friend__btn"
            :disabled="!link"
            @click="copyInviteLink"
            ><DoneText :done="isСopied">Копировать ссылку</DoneText></BaseButton
          >
        </div>
        <div
          v-if="error"
          class="form-error mb-s">
          {{ error }}
        </div>
        <div v-if="!countPending">
          <div
            v-if="invitesCount"
            class="mt-m text-center text-size-h4 text-bold">
            Твое приглашение уже принял<span v-if="invitesCount > 1">и</span>
            {{ invitesCount }} {{ countLabel }}
          </div>
        </div>
      </div>
    </BaseModalContent>
  </div>
</template>
<script>
import { copyToClipboard, delay, numCases } from "@/utils";
import { request } from "@/services/api";
import { userInviteFriendLink } from "@/utils/invites";
import DoneText from "@/components/DoneText";
export default {
  name: "InviteFriendModal",
  components: { DoneText },
  props: {},
  data() {
    return {
      error: "",
      isСopied: false,
      invitesCount: 0,
      countError: "",
      countPending: false,
    };
  },
  computed: {
    link() {
      return this.$store.state.participant.friendInviteLink;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    countLabel() {
      return numCases(["человек", "человека", "человек"], this.invitesCount);
    },
  },
  mounted() {
    const { link } = this;
    if (!link) {
      this.getLink();
    }
    this.getCount();
  },
  methods: {
    async copyInviteLink() {
      copyToClipboard(this.link);
      this.isСopied = true;
      await delay(1500);
      this.isСopied = false;
      this.$emit("close");
    },
    async getCount() {
      this.countPending = true;
      try {
        const { data } = await request({
          method: "GET",
          url: "/participant/invites_count",
        });
        if (data && typeof data === "number") {
          this.invitesCount = data;
        }
      } catch (error) {
        this.countError = error.message;
      }
      this.countPending = false;
    },
    async getLink() {
      const { data } = await request({
        method: "GET",
        url: "/participant/code/generate",
      });
      if (data && typeof data === "string") {
        const url = userInviteFriendLink({
          code: data,
          talent_id: this.user?.talent_id,
        });
        if (url) {
          this.$store.commit("participant/SET_STATE", {
            key: "friendInviteLink",
            value: url,
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invite-friend {
  &__image {
    max-width: 280px;
    margin: 20px auto 20px auto;
  }
  &__form {
    display: flex;
    flex-flow: row nowrap;
  }

  &__btn {
    flex-grow: 0;
    flex-shrink: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &__input {
    flex-grow: 1;
    border-top-right-radius: 0 !important;

    & ::v-deep .base-input__control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  @media screen and (max-width: @screen-xs) {
    &__form {
      flex-direction: column;
    }

    &__input {
      & ::v-deep .base-input__control {
        border-top-right-radius: @radius-s !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    &__btn {
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: @radius-s !important;
    }
  }
}
</style>
