<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <h2 class="text-bold text-size-h2 mb-m">
      Проверьте указанную дату рождения
    </h2>
    <div class="typography">
      <p>
        Вы&nbsp;участвуете в&nbsp;треке НТО Junior для школьников
        5&minus;7&nbsp;классов. В&nbsp;вашем профиле указана дата рождения
        <strong class="color-primary">{{ userDate }}</strong
        >, которая выглядит не&nbsp;соответствующей возрасту большинства
        учеников 5&minus;7&nbsp;классов.
      </p>

      <p>
        Если дата рождения действительно указана неверно, пожалуйста,
        скорректируйте&nbsp;ее&nbsp;в&nbsp;<a
          href="#"
          class="external-link"
          @click.prevent="goToAccount"
          >личном кабинете</a
        >
        на&nbsp;платформе «Талант».
      </p>

      <p>
        Случайно зарегистрировались не на тот трек НТО? Обратитесь в&nbsp;<a
          class="link"
          href="/support"
          @click.prevent="goToSupport"
          >службу поддержки.</a
        >
      </p>
    </div>

    <div class="buttons">
      <BaseButton @click="goToAccount">Изменить данные</BaseButton>
    </div>
  </BaseModalContent>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "CorrectnessPersonalDataModal",
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    userDate() {
      const { birthday } = this.user;
      if (!birthday) return "не указано";
      return dayjs(birthday).format("DD MMMM YYYY");
    },
  },
  methods: {
    goToAccount() {
      window.open(
        `${process.env.VUE_APP_TALENT_BASE_URL}/profile?view=personal_info`,
        "_talent_blank"
      );
      this.$emit("close");
    },

    goToSupport() {
      this.$router.push("/support");
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="less">
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
