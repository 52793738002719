<template>
  <div class="a-aside">
    <div
      v-if="privilege && !is4GradeJunior"
      class="a-aside__bonus mb-l"
      @click="$emit('openPrivilege', privilege.id)">
      <div>🎉&nbsp;Использовать привилегию</div>
    </div>
    <div class="a-aside__section">
      <router-link
        :to="{ name: 'user-stage-1' }"
        class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />
        1 этап
        <BaseIcon
          glyph="arrow-right"
          class="a-aside__title-arrow" />
      </router-link>

      <div v-if="!selectedProfiles.length">
        Выбери сферу, в которой планируешь участвовать.<br />
        <router-link
          class="link link--pseudo"
          :to="{ name: 'user-profiles' }"
          >Выбрать сферу</router-link
        >
      </div>

      <div v-else>
        <div
          v-for="profile in firstStageLinks"
          :key="profile.id">
          <SideBarLink
            :to="profile.to"
            :title="profile.title"
            :status="profile.status"
            :is-special="profile.is_special_project" />
        </div>
      </div>
    </div>

    <div
      v-if="!is4GradeJunior"
      class="a-aside__section"
      :class="{ 'is-disabled': !secondStage.isStarted }">
      <div class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />{{
          finalEvent && finalEvent.format === "online"
            ? "Дистанционный финал"
            : "Финал"
        }}
        <BaseIcon
          glyph="arrow-right"
          class="a-aside__title-arrow" />
      </div>
      <div
        v-if="finalEventInfo"
        class="mt-5">
        {{ finalEventInfo }}
      </div>
      <p v-if="finalStageDateStart">Этап начнется {{ finalStageDateStart }}</p>
      <p v-else-if="!secondStage.isStarted">Этап еще не начался</p>
      <div v-if="finalStageLinks.length">
        <div
          v-for="link in finalStageLinks"
          :key="link.id"
          class="mt-s">
          <SideBarLink
            :to="link.to"
            :title="link.title"
            :status="link.status"
            :is-special="link.special">
            <div
              v-if="link.msg"
              class="link-msg">
              {{ link.msg }}
            </div>
          </SideBarLink>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'user-profiles' }"
      class="a-aside__btn mb-l"
      ><BaseIcon glyph="tiles" /><span
        >Все сферы <span> НТО</span></span
      ></router-link
    >
    <ul class="a-aside__links">
      <li v-if="!is4GradeJunior">
        <router-link :to="{ name: 'user-privileges' }"
          >Мои привилегии</router-link
        >
      </li>

      <li>
        <router-link :to="{ name: 'user-achievements' }"
          >Мои достижения</router-link
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="$modal.show('invite-mentor')"
          >Пригласить наставника</a
        >
      </li>
      <li v-if="!is4GradeJunior">
        <a
          href="#"
          @click.prevent="$emit('inviteFriend', null)"
          >Пригласить друзей</a
        >
      </li>
      <li>
        <router-link :to="{ name: 'support' }">Служба поддержки</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import SideBarLink from "./SideBarLink";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "JuniorSideBar",
  components: { SideBarLink },
  props: {
    privilege: {
      type: Object,
    },
    selectedProfiles: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      selectedList: "participant/selectedList",
      secondStage: "participant/secondStage",
      finalStageList: "participant/finalStageList",
      is4GradeJunior: "participant/is4GradeJunior",
    }),
    finalAchievements() {
      return this.$store.state.participant.finalAchievements;
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    // начало финального этапа
    finalStageDateStart() {
      const { syncTimeStamp, finalEvent } = this;
      if (!finalEvent) return undefined;
      // Если не началось
      if (finalEvent.start && dayjs(syncTimeStamp).isBefore(finalEvent.start)) {
        return dayjs(syncTimeStamp).to(finalEvent.start);
      }
      return undefined;
    },
    finalEvent() {
      return this.$store.getters["participant/juniorFinalEvent"];
    },
    finalEventInfo() {
      const { finalEvent } = this;
      if (!this.finalStageList?.length) return;
      if (finalEvent?.format === "offline") {
        return `Очный формат. Регион финала: ${finalEvent.region_with_type}`;
      }
      if (finalEvent?.format === "online") {
        return `Индивидуальный формат`;
      }
      return undefined;
    },
    finalStageLinks() {
      const { finalStageList, profiles } = this;
      if (!finalStageList?.length) return [];
      return finalStageList.map((n) => {
        const profile = profiles[n.profile_id];
        const hasAchievements =
          this.finalAchievements[n.profile_id]?.length > 0;
        const result = {
          to: {
            name: "user-profile-stage-2",
            params: {
              profile_id: n.profile_id,
            },
          },
          id: n.profile_id,
          status: n.failed ? "warn" : hasAchievements ? "ok" : "pending",
          special: profile?.is_special_project,
          title: profile?.title,
        };
        return result;
      });
    },
    firstStageLinks() {
      const { selectedProfiles, finalStageList } = this;
      return selectedProfiles.map((profile) => {
        const link = {
          to: {
            name: "user-profile-stage-1",
            params: {
              profile_id: profile.id,
            },
          },
          id: profile.id,
          status: profile.failed ? "warn" : "pending",
          special: profile.is_special_project,
          title: profile.title,
        };
        // Если юзер прошел в командный этап по профилю
        // подсветим пункт меню
        const stage = finalStageList?.find(
          (n) => +n.profile_id === +profile.id
        );
        if (stage) {
          link.status = "ok";
        }
        return link;
      });
    },
  },
};
</script>

<style lang="less" src="./sidebar.less"></style>
