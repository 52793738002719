<template>
  <PageLayout>
    <router-view />
    <NovelSection
      v-if="!isStudent"
      :is-visible="isNovelVisible" />
    <template #modals>
      <InviteMentorModal />
    </template>
    <template #aside>
      <Component
        :is="sideBarComponent"
        :selected-profiles="selectedProfiles"
        :privilege="privilege"
        @inviteFriend="openInviteFriendModal"
        @openPrivilege="handleOpenPrivilege" />
    </template>
  </PageLayout>
</template>

<script>
import { mapGetters } from "vuex";
import PageLayout from "./PageLayout";
import JuniorSideBar from "@/components/sidebar/JuniorSideBar";
import StudentSideBar from "@/components/sidebar/StudentSideBar";
import SchoolSideBar from "@/components/sidebar/SchoolSideBar";
import InviteMentorModal from "@/components/user/modals/InviteMentorModal";
import InviteFriendModal from "@/components/user/modals/InviteFriendModal.vue";
import ExtraScoresModal from "@/components/user/modals/ExtraScoresModal.vue";
import UpdateEducationModal from "@/components/user/modals/UpdateEducationModal";
import YandexMissmatchModal from "@/modals/YandexMissmatchModal";
import NovelSection from "@/components/NovelSection";
import AgeConflictModal from "@/components/user/modals/AgeConflictModal.vue";
// import { numCases } from "@/utils";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import dayjs from "@/plugins/dayjs";
import { getToken } from "@/services/api";
// import { talentRequest } from "@/services/api";
// import { getJuniorFinalEvent } from "@/utils/participant";
export default {
  name: "UserLayout",
  components: {
    PageLayout,
    StudentSideBar,
    JuniorSideBar,
    InviteMentorModal,
    SchoolSideBar,
    NovelSection,
    AgeConflictModal,
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isJunior: "participant/isJunior",
      juniorFinalEvent: "participant/juniorFinalEvent",
      isStudent: "participant/isStudent",
      selectedList: "participant/selectedList",
      notUsedPrivileges: "participant/notUsedPrivileges",
    }),

    profiles() {
      return this.$store.state.profile.profiles;
    },
    /**
     * Берем привилегию, не использаванну и с не протухшей
     * датой, с самой ранней датой
     */
    privilege() {
      const list = this.notUsedPrivileges;
      if (!list?.length) return;
      const sortedDates = list
        .filter((n) => {
          return !!n.expired_at;
        })
        .sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime() > 0
            ? -1
            : 1;
        });
      if (sortedDates.length) {
        return sortedDates[0];
      } else {
        return list[0];
      }
    },
    sideBarComponent() {
      const { isJunior, isStudent } = this;

      if (isJunior) return "JuniorSideBar";
      else if (isStudent) return "StudentSideBar";
      return "SchoolSideBar";
    },
    selectedProfiles() {
      const { selectedList, profiles } = this;
      return selectedList.reduce((acc, value) => {
        if (profiles[value.profile_id]) {
          acc.push({
            ...profiles[value.profile_id],
            failed: value.failed,
          });
        }
        return acc;
      }, []);
    },
    isNovelVisible() {
      return this.$route?.name === "user-index";
    },
  },
  async mounted() {
    // если не получали образования юзера, то запросим
    try {
      await this.$store.dispatch("user/getEducation");
      this.checkEducation();
    } catch (error) {
      // ничего не делаем
    }
    // await this.checkJuniorFinalEvent();
    if (!this.isJunior) {
      this.checkYandex();
    }
    this.checkUserAge();

    this.timer = setInterval(() => {
      const token = getToken();
      if (!token) {
        clearInterval(this.timer);
        window.location.href = `${window.location.origin}/login`;
      }
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    /**
     * Проверяем, соответствие `talent_education_id` у участника
     * с текущим образованием в таланте, если не соответствует, то выкидываем
     * модалку о необходимости уточнить образование
     * (важно для генерации дипломов)
     */
    checkEducation() {
      const { user } = this;
      const ontiEducation = this.$store.state.participant.talent_education_id;
      const userEducation = user.current_education;
      if (!userEducation || !ontiEducation || userEducation !== ontiEducation) {
        this.openUpdateEducationModal();
        return;
      }

      const currentEducation = this.$store.state.user.educations.find(
        (n) => n.id === this.$store.state.participant.talent_education_id
      );

      if (
        currentEducation &&
        !currentEducation.address &&
        !currentEducation.dadata_hid &&
        !currentEducation.organization
      ) {
        this.openUpdateEducationModal();
      }
    },
    /**
     * Проверяем, что привязанный в НТО степик аккаунт - существует у юзера в таланте
     * если не существуем - то нужно выкинуть предупреждение о том, что этого аккаунта нет в таланте
     */
    async checkYandex() {
      const isValid = await this.$store.dispatch("user/checkYandex");
      if (!isValid) {
        this.$modal.show(YandexMissmatchModal, {}, MODAL_DYNAMIC_DEFAULTS);
      }
    },
    openUpdateEducationModal() {
      this.$modal.show(
        UpdateEducationModal,
        {},
        { ...MODAL_DYNAMIC_DEFAULTS, classes: ["v--modal", "no-overflow"] }
      );
    },
    openInviteFriendModal() {
      this.$modal.show(InviteFriendModal, {}, MODAL_DYNAMIC_DEFAULTS);
    },
    handleOpenPrivilege(id) {
      this.$modal.show(
        ExtraScoresModal,
        {
          privilege: id,
        },
        {
          ...MODAL_DYNAMIC_DEFAULTS,
          width: 800,
        }
      );
    },
    checkUserAge() {
      if (!this.isJunior) return;
      const { user } = this;
      // исклюючаем 4-клашек
      if (user.grade === 4) return;
      const userBirthday = dayjs(user.birthday);
      const seasonDate = this.$store.state.season.start_at;
      let year = new Date().getFullYear();
      if (seasonDate) {
        year = new Date(seasonDate).getFullYear();
      }
      if (
        !user.birthday ||
        userBirthday < dayjs(`${year - 14}-08-01`) ||
        userBirthday > dayjs(`${year - 10}-09-01`)
      ) {
        this.$modal.show(AgeConflictModal, {}, MODAL_DYNAMIC_DEFAULTS);
      }
    },
    // async checkJuniorFinalEvent() {
    //   const { selectedList, juniorFinalEvent, isJunior, user } = this;
    //   if (!isJunior || !selectedList?.length) return;
    //   if (juniorFinalEvent?.region_with_type === user?.region_with_type) {
    //     return;
    //   }
    //   const profile =
    //     this.$store.state.profile.profiles[selectedList[0]?.profile_id];

    //   const eventIds = profile?.steps
    //     .filter((n) => n.stage === FINAL_STAGE && n.talent_event_id)
    //     .map((n) => n.talent_event_id);
    //   await this.$store.dispatch("profile/getEvents", eventIds);
    //   const events = eventIds.map((id) => this.$store.state.profile.events[id]);
    //   const newEvent = getJuniorFinalEvent(
    //     events,
    //     user.region_with_type,
    //     this.$store.state.user.educations
    //   );
    //   if (!newEvent) return;
    //   try {
    //     if (newEvent?.id !== juniorFinalEvent?.id) {
    //       // patch final event
    //       await request({
    //         method: "PATCH",
    //         url: `/profiles/${profile?.id}`,
    //         data: {
    //           stage: INDIVIDUAL_STAGE,
    //           final_event: newEvent?.id,
    //         },
    //       });
    //       this.$store.commit("participant/CHANGE_PROFILE", {
    //         ...selectedList[0],
    //         final_event: newEvent?.id,
    //       });
    //     }
    //   } catch (error) {
    //     console.log("error", error);
    //   }
    // },
  },
};
</script>
