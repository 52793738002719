<template>
  <BaseModalContent
    name="yandex-missmatch"
    :dynamic="true"
    @close="$emit('close')">
    <h2 class="text-size-h2 text-bold mb-m">
      Привяжите аккаунт Яндекс&nbsp;ID
    </h2>
    <div class="typography mb-m">
      <p>
        Вы удалили связь с аккаунтом Яндекс&nbsp;ID
        {{ yandexId }} <br />
        и своей учетной записи на платформе «Талант». По правилам НТО вы должны
        решать задания с одного аккаунта Яндекс&nbsp;ID, иначе вас могут
        дисквалифицировать.
      </p>
      <p>
        Пожалуйста,
        <a
          :href="talentLink"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
          >привяжите аккаунт Яндекс&nbsp;ID</a
        >
        {{ yandexId }} к своей учетной записи на платформе «Талант» ({{
          user.email
        }}).
      </p>
      <p>
        Если у вас возникли проблемы,
        <a
          href="#"
          class="link"
          @click.prevent="handleSupport"
          >напишите нам</a
        >, мы вам поможем.
      </p>
    </div>
    <BaseButton @click="$emit('close')">Закрыть</BaseButton>
  </BaseModalContent>
</template>

<script>
import { config } from "@/config";

export default {
  name: "YandexMIssmatchModal",
  computed: {
    yandexId() {
      return this.$store.getters["user/yandexId"];
    },
    talentLink() {
      return `${config.TALENT_BASE_URL}/profile/yandex_id`;
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    handleSupport() {
      this.$router.push({ name: "support" });
      this.$emit("close");
    },
  },
};
</script>

<style></style>
