<template>
  <div class="a-aside">
    <div
      v-if="privilege"
      class="a-aside__bonus mb-l"
      @click="$emit('openPrivilege', privilege.id)">
      <div>🎉&nbsp;Использовать привилегию</div>
    </div>
    <div class="a-aside__section">
      <router-link
        :to="{ name: 'user-stage-1' }"
        class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />
        1 этап
        <BaseIcon
          glyph="arrow-right"
          class="a-aside__title-arrow" />
      </router-link>

      <div v-if="!selectedProfiles.length">
        Выберите профили и&nbsp;спецпроекты в&nbsp;которых вы&nbsp;будете
        участвовать<br />
        <router-link
          class="link link--pseudo"
          :to="{ name: 'user-profiles' }"
          >Выбрать профили</router-link
        >
      </div>
      <div v-else>
        <div
          v-for="link in firstStageLinks"
          :key="link.id">
          <SideBarLink
            :to="link.to"
            :title="link.title"
            :status="link.status"
            :is-special="link.special">
          </SideBarLink>
        </div>
      </div>
      <!-- <div> @TODO Вы можете выбрать еще 3 профиля и спецпроекты</div> -->
    </div>
    <!-- Второй этап -->
    <div
      class="a-aside__section"
      :class="{
        'is-disabled': !secondStage.isStarted,
      }">
      <router-link
        :to="{ name: 'user-stage-2' }"
        class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />
        2 этап
        <BaseIcon
          glyph="arrow-right"
          class="a-aside__title-arrow" />
      </router-link>
      <div v-if="teamStageProfiles.length">
        <div
          v-for="link in secondStageLinks"
          :key="link.id">
          <SideBarLink
            :to="link.to"
            :title="link.title"
            :status="link.status"
            :is-special="link.special">
            <div
              v-if="link.msg"
              class="link-msg">
              {{ link.msg }}
            </div>
          </SideBarLink>
        </div>
      </div>
      <p v-if="secondStageDateStart">
        Этап начнется {{ secondStageDateStart }}
      </p>
      <p v-else-if="!secondStage.isStarted">Этап еще не начался</p>
      <!-- <div> @TODO Вы можете выбрать еще 3 профиля и спецпроекты</div> -->
    </div>
    <!-- Финальный этап -->
    <div
      class="a-aside__section"
      :class="{ 'is-disabled': !thirdStage.isStarted }">
      <div class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />Финал
        <!-- <BaseIcon glyph="arrow-right" class="a-aside__title-arrow" /> -->
      </div>
      <p v-if="finalStageDateStart">Этап начнется {{ finalStageDateStart }}</p>
      <p v-else-if="!thirdStage.isStarted">Этап еще не начался</p>

      <div v-if="finalStageLinks.length">
        <div
          v-for="link in finalStageLinks"
          :key="link.id"
          class="mt-s">
          <SideBarLink
            :to="link.to"
            :title="link.title"
            :status="link.status"
            :is-special="link.special">
            <div
              v-if="link.msg"
              class="link-msg">
              {{ link.msg }}
            </div>
          </SideBarLink>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'user-profiles' }"
      class="a-aside__btn mb-l"
      ><BaseIcon glyph="tiles" /><span
        >Все профили <span> НТО</span></span
      ></router-link
    >
    <ul class="a-aside__links">
      <li>
        <router-link :to="{ name: 'user-privileges' }"
          >Мои привилегии</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'user-achievements' }"
          >Мои достижения</router-link
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="$modal.show('invite-mentor')"
          >Пригласить наставника</a
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="$emit('inviteFriend', null)"
          >Пригласить друзей</a
        >
      </li>
      <li>
        <router-link :to="{ name: 'support' }">Служба поддержки</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * Сайдбар для Школьников участвующих в большой НТО
 */
import SideBarLink from "./SideBarLink";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
import { HIDE_TEAMS_PROPFILE } from "@/constants";
export default {
  name: "SchoolSideBar",
  components: { SideBarLink },
  props: {
    privilege: {
      type: Object,
    },
    selectedProfiles: {
      type: Array,
    },
  },
  computed: {
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    ...mapGetters({
      teamStageList: "participant/teamStageList",
      stageSchedule: "participant/stageSchedule",
      secondStage: "participant/secondStage",
      thirdStage: "participant/thirdStage",
      finalStageList: "participant/finalStageList",
    }),
    finalAchievements() {
      return this.$store.state.participant.finalAchievements;
    },

    profiles() {
      return this.$store.state.profile.profiles;
    },
    firstStageLinks() {
      const { selectedProfiles, teamStageList } = this;
      return selectedProfiles.map((profile) => {
        const link = {
          to: {
            name: "user-profile-stage-1",
            params: {
              profile_id: profile.id,
            },
          },
          id: profile.id,
          status: profile.failed ? "warn" : "pending",
          special: profile.is_special_project,
          title: profile.title,
        };
        // Если юзер прошел в командный этап по профилю
        // подсветим пункт меню
        const stage = teamStageList?.find((n) => +n.profile_id === +profile.id);
        if (stage) {
          link.status = "ok";
        }
        return link;
      });
    },
    secondStageLinks() {
      const { teamStageProfiles, teamStageList, finalStageList } = this;
      return teamStageProfiles.map((profile) => {
        const link = {
          to: {
            name: "user-profile-stage-2",
            params: {
              profile_id: profile.id,
            },
          },
          id: profile.id,
          status: "pending",
          special: profile.is_special_project,
          title: profile.title,
        };
        // Если юзер прошел в командный этап по профилю
        // подсветим пункт меню
        const stage = teamStageList?.find((n) => +n.profile_id === +profile.id);
        if (
          stage &&
          !stage.team_id &&
          !HIDE_TEAMS_PROPFILE.includes(profile.id)
        ) {
          link.status = "warn";
          link.msg = "Необходимо создать или вступить в команду";
        }
        if (finalStageList.some((n) => n.profile_id === profile.id)) {
          link.status = "ok";
          link.msg = "";
        }
        return link;
      });
    },
    finalStageLinks() {
      const { finalStageList, profiles } = this;
      if (!finalStageList?.length) return [];
      return finalStageList.map((n) => {
        const profile = profiles[n.profile_id];
        const hasAchievements =
          this.finalAchievements[n.profile_id]?.length > 0;
        const result = {
          to: {
            name: "user-profile-stage-3",
            params: {
              profile_id: n.profile_id,
            },
          },
          id: n.profile_id,
          status: n.failed ? "warn" : hasAchievements ? "ok" : "pending",
          special: profile?.is_special_project,
          title: profile?.title,
        };
        return result;
      });
    },
    // Начало второго этапа для Большого ОНТИ
    secondStageDateStart() {
      const { secondStage, syncTimeStamp } = this;
      if (!secondStage.start_at || secondStage.isStarted) {
        return undefined;
      }
      return dayjs(syncTimeStamp).to(secondStage.start_at);
    },
    // начало финального этапа
    finalStageDateStart() {
      const { thirdStage, syncTimeStamp } = this;
      if (!thirdStage.start_at || thirdStage.isStarted) {
        return undefined;
      }
      return dayjs(syncTimeStamp).to(thirdStage.start_at);
    },
    teamStageProfiles() {
      const { teamStageList, profiles } = this;
      if (!teamStageList) return [];
      return teamStageList.reduce((acc, value) => {
        if (profiles[value.profile_id]) {
          acc.push(profiles[value.profile_id]);
        }
        return acc;
      }, []);
    },
  },
};
</script>

<style lang="less" src="./sidebar.less"></style>
