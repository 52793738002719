<template>
  <div class="a-aside">
    <div
      v-if="privilege"
      class="a-aside__bonus mb-l"
      @click="$emit('openPrivilege', privilege.id)">
      <div>🎉&nbsp;Использовать привилегию</div>
    </div>
    <div class="a-aside__section">
      <router-link
        :to="{ name: 'user-stage-1' }"
        class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />
        1 этап
        <BaseIcon
          glyph="arrow-right"
          class="a-aside__title-arrow" />
      </router-link>

      <div v-if="!selectedProfiles.length">
        Выберите профили и&nbsp;спецпроекты в&nbsp;которых вы&nbsp;будете
        участвовать<br />
        <router-link
          class="link link--pseudo"
          :to="{ name: 'user-profiles' }"
          >Выбрать профили</router-link
        >
      </div>
      <div v-else>
        <div
          v-for="link in firstStageLinks"
          :key="link.id">
          <SideBarLink
            :to="link.to"
            :title="link.title"
            :status="link.status"
            :is-special="link.special">
            <div
              v-if="link.msg"
              class="link-msg">
              {{ link.msg }}
            </div>
          </SideBarLink>
        </div>
      </div>
      <!-- <div> @TODO Вы можете выбрать еще 3 профиля и спецпроекты</div> -->
    </div>

    <div
      class="a-aside__section"
      :class="{ 'is-disabled': !secondStage.isStarted }">
      <div class="a-aside__title a-aside__title--link">
        <BaseIcon
          glyph="star"
          class="a-aside__title-icon" />Финал
        <BaseIcon
          glyph="arrow-right"
          class="a-aside__title-arrow" />
      </div>
      <p v-if="finalStageDateStart">Этап начнется {{ finalStageDateStart }}</p>
      <p v-else-if="!secondStage.isStarted">Этап еще не начался</p>
      <div v-if="finalStageLinks.length">
        <div
          v-for="link in finalStageLinks"
          :key="link.id"
          class="mt-s">
          <SideBarLink
            :to="link.to"
            :title="link.title"
            :status="link.status"
            :is-special="link.special">
            <div
              v-if="link.msg"
              class="link-msg">
              {{ link.msg }}
            </div>
          </SideBarLink>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'user-profiles' }"
      class="a-aside__btn mb-l"
      ><BaseIcon glyph="tiles" /><span
        >Все профили <span> НТО</span></span
      ></router-link
    >
    <ul class="a-aside__links">
      <li>
        <router-link :to="{ name: 'user-privileges' }"
          >Мои привилегии</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'user-achievements' }"
          >Мои достижения</router-link
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="$modal.show('invite-mentor')"
          >Пригласить наставника</a
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="$emit('inviteFriend', null)"
          >Пригласить друзей</a
        >
      </li>
      <li>
        <router-link :to="{ name: 'support' }">Служба поддержки</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * Сайдбар для студентов
 */
import SideBarLink from "./SideBarLink";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
import { HIDE_TEAMS_PROPFILE } from "@/constants";
export default {
  name: "StudentSideBar",
  components: { SideBarLink },
  props: {
    privilege: {
      type: Object,
    },
    selectedProfiles: {
      type: Array,
    },
  },
  computed: {
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    ...mapGetters({
      selectedList: "participant/selectedList",
      teamStageList: "participant/teamStageList",
      isStudent: "participant/isStudent",
      stageSchedule: "participant/stageSchedule",
      secondStage: "participant/secondStage",
      finalStageList: "participant/finalStageList",
    }),
    firstStageLinks() {
      const { selectedProfiles, teamStageList } = this;
      return selectedProfiles.map((profile) => {
        const link = {
          to: {
            name: "user-profile-stage-1",
            params: {
              profile_id: profile.id,
            },
          },
          id: profile.id,
          status: "pending",
          special: profile.is_special_project,
          title: profile.title,
        };

        const stage = teamStageList?.find((n) => +n.profile_id === +profile.id);
        // если нет команды, то надо подсветить
        if (
          stage &&
          !stage.team_id &&
          !HIDE_TEAMS_PROPFILE.includes(profile.id)
        ) {
          link.status = "warn";
          link.msg = "Необходимо создать или вступить в команду";
        }
        return link;
      });
    },
    // начало финального этапа
    finalStageDateStart() {
      const { secondStage, syncTimeStamp } = this;
      if (!secondStage) return undefined;
      // Если не началось
      if (secondStage.start_at && !secondStage.isStarted) {
        return dayjs(syncTimeStamp).to(secondStage.start_at);
      }
      return undefined;
    },
    finalStageLinks() {
      const { finalStageList, profiles } = this;
      if (!finalStageList?.length) return [];
      return finalStageList.map((n) => {
        const profile = profiles[n.profile_id];
        const result = {
          to: {
            name: "user-profile-stage-2",
            params: {
              profile_id: n.profile_id,
            },
          },
          id: n.profile_id,
          status: n.failed ? "warn" : "pending",
          special: profile?.is_special_project,
          title: profile?.title,
        };
        return result;
      });
    },
  },
};
</script>

<style lang="less" src="./sidebar.less"></style>
