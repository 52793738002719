<template>
  <Modal
    class="invite-mentor"
    :name="name"
    adaptive
    scrollable
    :min-height="400"
    height="auto">
    <BaseModalContent :name="name">
      <h2 class="text-bold text-size-h2 mb-s">Пригласить наставника</h2>
      <p class="mb-l">
        Чтобы пригласить наставника, скопируйте и&nbsp;отправьте ему ссылку
        любым удобным способом.
      </p>
      <BaseImage
        class="invite-mentor__image mb-l"
        :aspect-ratio="201 / 280"
        src="@/assets/images/invite-mentor.svg" />

      <div class="invite-mentor__form">
        <BaseInput
          id="invite-link-mentor"
          class="invite-mentor__input"
          :value="url"
          readonly />

        <BaseButton
          id="modal-copy-link"
          class="invite-mentor__btn"
          @click="copyInviteLink"
          ><DoneText :done="isCopied">Копировать ссылку</DoneText></BaseButton
        >
      </div>
      <div
        v-if="error"
        class="form-error mb-s">
        {{ error }}
      </div>
    </BaseModalContent>
  </Modal>
</template>

<script>
import { copyToClipboard, delay } from "@/utils";
import DoneText from "@/components/DoneText";

export default {
  name: "InviteMentorModal",
  components: {
    DoneText,
  },
  data() {
    return {
      name: "invite-mentor",
      url: "",
      error: "",
      pending: true,
      isCopied: false,
    };
  },
  computed: {},
  watch: {
    selected: {
      handler(val) {
        if (val && val.id) {
          this.getLink();
        }
      },
    },
  },
  async created() {
    try {
      this.pending = true;
      const link = await this.$store.dispatch(
        "participant/getMentorInviteLink"
      );
      this.url = link;
    } catch (error) {
      this.error = "Ошибка получения ссылки-приглашения";
    }
    this.pending = false;
  },
  methods: {
    async copyInviteLink() {
      this.isCopied = true;
      copyToClipboard(this.url);
      await delay(1500);
      this.isCopied = false;
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="less" scoped>
.invite-mentor {
  &__image {
    max-width: 280px;
    margin: 20px auto 20px auto;
  }

  &__form {
    display: flex;
    flex-flow: row nowrap;
  }

  &__btn {
    flex-grow: 0;
    flex-shrink: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &__input {
    flex-grow: 1;
    border-top-right-radius: 0 !important;

    & ::v-deep .base-input__control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  @media screen and (max-width: @screen-xs) {
    &__form {
      flex-direction: column;
    }

    &__input {
      & ::v-deep .base-input__control {
        border-top-right-radius: @radius-s !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    &__btn {
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: @radius-s !important;
    }
  }
}
</style>
